/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ChatScreen from 'screens/root/messages/ChatScreen';
import CourseCreateScreen from 'screens/courses/create/CourseCreateScreen';
import CourseResultsScreen from 'screens/courses/results/CourseResultsScreen';
import { Platform, View } from 'react-native';
import useTheme from 'hooks/useTheme';
import EventDetailScreen from 'screens/events/detail/EventDetailScreen';
import VehiclesScreen from 'screens/root/profile/views/vehicles/VehiclesScreen';
import CreateVehicleScreen from 'screens/root/profile/views/vehicles/CreateVehicleScreen';
import CatalogScreen from 'screens/catalog/CatalogScreen';
import RootScreen from 'screens/root/RootScreen';
import LoginScreen from 'screens/auth/login/LoginScreen';
import RegisterScreen from 'screens/auth/register/RegisterScreen';
import ValidateAccountScreen from 'screens/auth/validateAccount/ValidateAccountScreen';
import ThirdPartyProfileScreen from 'screens/root/profile/ThirdPartyProfileScreen';
import CourseDetailScreen from 'screens/courses/detail/CourseDetailScreen';
import InstanceDetailScreen from 'screens/courses/detail/InstanceDetailScreen';
import AboutScreen from 'screens/auth/about/AboutScreen';
import { useTranslation } from 'react-i18next';
import {
  ProfileRightAvatar,
  ProfileTitle,
} from 'components/headers/ProfileHeaders';
import SectionDetailScreen from 'screens/courses/detail/SectionDetailScreen';
import NotificationsScreen from 'screens/root/notifications/NotificationsScreen';
import HeaderNotifications from 'components/headers/HeaderNotifications';
import isDesktopMode from 'utils/desktopMode';
import HeaderShape, { HeaderLogo } from 'components/headers/HeaderShape';
import { Icon } from 'components/common/Icon/Icon';
import HeaderHelp from 'components/headers/HeaderHelp';
import * as Linking from 'expo-linking';
import ForgotPasswordScreen from '../screens/auth/forgotPassword/ForgotPasswordScreen';
import CourseValidateScreen from '../screens/courses/validate/CourseValidateScreen';
import CourseEvaluateScreen from '../screens/courses/evaluate/CourseEvaluateScreen';
import { RootStackParamList } from './RootStackParamList';
import CourseSearchScreen from '../screens/courses/search/CourseSearchScreen';
import BootScreen from '../screens/boot/BootScreen';
import WelcomeScreen from '../screens/welcome/WelcomeScreen';
import ResetPasswordScreen from '../screens/auth/resetPassword/ResetPasswordScreen';

const MainStack = createStackNavigator<RootStackParamList>();
// export type ScreenProps = NativeStackScreenProps<RootStackParamList, 'Root'>;

export const RootStack = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const desktopMode = isDesktopMode();

  let queryParams: any | undefined = {};
  if (Platform.OS === 'web') {
    if (window?.location?.search) {
      const urlParams = new URLSearchParams(window?.location?.search);
      urlParams.forEach((value, key) => {
        queryParams[key] = value;
      });
    }
  } else {
    const url = Linking.useURL();
    queryParams = url ? Linking.parse(url) : {};
  }

  console.log(queryParams);

  return (
    <MainStack.Navigator
      screenOptions={{
        headerTitleAlign: 'center',
        headerTintColor: theme.colors.blueHigh,
        headerTransparent: true,
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <View
            style={{
              width: theme.normalize(50),
              alignItems: 'center',
              height:
                desktopMode || Platform.OS === 'web'
                  ? undefined
                  : theme.headerSizes.mobile,
            }}
          >
            <Icon name="arrow-left" type="FontAwesome5" sizeName="larger" />
          </View>
        ),
        headerTitleStyle: {
          fontSize: theme.fontSizes.fs18,
          fontWeight: '700',
          fontFamily: theme.fonts.fontTitle,
          height:
            desktopMode || Platform.OS === 'web'
              ? undefined
              : theme.headerSizes.mobile,
        },
        headerStyle: {
          height: desktopMode
            ? theme.headerSizes.desktop
            : theme.headerSizes.mobile,
        },
        headerBackground: () => <HeaderShape />,
      }}
    >
      <MainStack.Screen
        name="Boot"
        component={BootScreen}
        options={{
          headerShown: false,
          title: t('titlePages.home'),
        }}
        initialParams={queryParams ? { queryParams } : null}
      />
      <MainStack.Screen
        name="Login"
        component={LoginScreen}
        options={{
          title: t('titlePages.login'),
          headerLeft: () => <HeaderHelp />,
        }}
      />

      <MainStack.Screen
        name="Register"
        component={RegisterScreen}
        options={{
          title: t('titlePages.createAccount'),
        }}
      />
      <MainStack.Screen
        name="ForgotPassword"
        component={ForgotPasswordScreen}
        options={{
          title: t('titlePages.forgotPassword'),
        }}
      />
      <MainStack.Screen
        name="ResetPassword"
        component={ResetPasswordScreen}
        options={{
          title: t('titlePages.resetPassword'),
        }}
      />
      <MainStack.Screen
        name="About"
        component={AboutScreen}
        options={{
          title: t('titlePages.about'),
        }}
      />
      <MainStack.Screen
        name="ValidateAccount"
        component={ValidateAccountScreen}
        options={{
          title: t('titlePages.validateAccount'),
        }}
      />
      <MainStack.Screen
        name="Catalog"
        component={CatalogScreen}
        // options={{ headerShown: false }}
      />
      <MainStack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={() => ({
          headerTitle: () => <HeaderLogo />,
          title: t('titlePages.home'),
          headerLeft: () => <HeaderHelp />,
        })}
      />
      <MainStack.Screen
        name="Root"
        component={RootScreen}
        options={() => ({
          headerShown: false,
          title: t('titlePages.home'),
          headerLeft: () => <HeaderHelp />,
        })}
      />
      <MainStack.Screen
        name="Chat"
        component={ChatScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.chat'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="SectionDetail"
        component={SectionDetailScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.courseDetail'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="CourseDetail"
        component={CourseDetailScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.courseDetail'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="InstanceDetail"
        component={InstanceDetailScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.courseDetail'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="CourseCreate"
        component={CourseCreateScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.courseCreate'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="CourseSearch"
        component={CourseSearchScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.courseSearch'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="CourseResults"
        component={CourseResultsScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.courseResults'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="CourseValidate"
        component={CourseValidateScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.courseValidate'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="CourseEvaluate"
        component={CourseEvaluateScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.courseEvaluate'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      {/* TODO: Adapter pour avoir le nom de l'utilisateur sur lequel on clique */}
      <MainStack.Screen
        name="ThirdPartyProfile"
        component={ThirdPartyProfileScreen}
        initialParams={{ profileId: 0 }}
        options={({ route }: any) => ({
          title: t('titlePages.profile'),
          headerRight: () => (
            <ProfileRightAvatar profileId={route.params.profileId} />
          ),
          headerTitle: () => (
            <ProfileTitle profileId={route.params.profileId} />
          ),
        })}
      />
      <MainStack.Screen
        name="CreateVehicle"
        component={CreateVehicleScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.createVehicule'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="Vehicles"
        component={VehiclesScreen}
        options={() => ({
          title: t('titlePages.profileVehicles'),
        })}
      />
      <MainStack.Screen
        name="EventDetail"
        component={EventDetailScreen}
        options={({ route, navigation }: any) => ({
          title: t('titlePages.eventDetail'),
          headerRight: () => (
            <HeaderNotifications route={route} navigation={navigation} />
          ),
        })}
      />
      <MainStack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{
          title: t('titlePages.notifications'),
        }}
      />
    </MainStack.Navigator>
  );
};
